$(document).ready(function() {

  $('.kachel').matchHeight();
  var tl_start = new TimelineMax();
  tl_start.staggerFrom('.my-btn', 2, {scale:0, opacity:0}, 1)
          .from('.log:last', 1, {y:+100},0);

  $('.listen > li').prepend('<i class = "fa fa-check"></i>');


});
